import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Signup } from 'src/app/public/access/access.model';
import { AccessService } from 'src/app/public/access/access.service';
import { SnackbarService } from 'src/app/shared/notifications/snackbar.service';
import { MobileVerificationComponent } from '../mobile-verification/mobile-verification.component';
import { SignInComponent } from '../sign-in/sign-in.component';

@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.scss']
})
export class SignUpComponent implements OnInit {

  // Set variables
  isLoading = false;
  hide = true;
  countryCode: string;

  constructor(@Inject(MAT_DIALOG_DATA) private data, private formBuilder: FormBuilder, public accessService: AccessService, public snackbarService: SnackbarService, private dialogRef: MatDialogRef<SignUpComponent>, public dialog: MatDialog) { }

  // Form details
  signupDetails: Signup = {
    firstName: '',
    lastName: '',
    email: '',
    countryCode: '',
    mobile: ''
  }

  // Form builder
  signupForm = this.formBuilder.group({
    firstName: [null, Validators.required],
    lastName: [null, Validators.required],
    email: [null, [Validators.required, Validators.email]],
    mobile: [null, [Validators.required, Validators.minLength(10), Validators.maxLength(10)]],
  });

  ngOnInit(): void {
    this.countryCode = "91";

    // Patch values
    if (this.data.mobile) {
      this.patchValues(this.data);
    }
  }

  // Form getter
  get signupControls() {
    return this.signupForm.controls;
  }

  // Patch values
  patchValues(values) {
    this.signupForm.patchValue({
      mobile: values.mobile
    });
  }

  // On form submit
  onSubmit() {
    // Check form validation
    if (this.signupForm.valid) {
      this.isLoading = true;
      this.signupDetails = {
        firstName: this.signupControls.firstName.value,
        lastName: this.signupControls.lastName.value,
        email: this.signupControls.email.value,
        countryCode: this.countryCode,
        mobile: this.signupControls.mobile.value
      }
      this.accessService.sendRegistrationOTP(this.signupDetails).subscribe(resultData => {
        this.isLoading = false;
        let codeDetails = {
          type: 'registration',
          firstName: this.signupControls.firstName.value,
          lastName: this.signupControls.lastName.value,
          email: this.signupControls.email.value,
          countryCode: resultData.countryCode,
          mobile: resultData.mobile,
          hash: resultData.hash
        }
        this.onMobileVerification(codeDetails);
        this.snackbarService.success('Verification code sent on your mobile number', 'Dismiss', 'bottom', 4000);
      }, (error: any) => {
        this.isLoading = false;
        console.log(error);
      });
    }
  }

  // On mobile verification
  onMobileVerification(codeDetails: any) {
    this.onClose(false);
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = "420px";
    dialogConfig.position = { top: '5%' };
    dialogConfig.data = {
      code: codeDetails
    };
    this.dialog.open(MobileVerificationComponent, dialogConfig)
      .afterClosed().subscribe(status => {
        if (status) {
        }
      });
  }

  // On signin
  onSignin() {
    this.onClose(false);
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = "420px";
    dialogConfig.position = { top: '5%' };
    this.dialog.open(SignInComponent, dialogConfig)
      .afterClosed().subscribe(status => {
        if (status) {
        }
      });
  }

  // Number validation 
  onKeypress(event: any) {
    const pattern = /[0-9\ ]/;
    let inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode != 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  // Close modal
  onClose(status: boolean) {
    this.dialogRef.close(status);
  }
}
