import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AccessService } from 'src/app/public/access/access.service';

@Component({
  selector: 'app-cities',
  templateUrl: './cities.component.html',
  styleUrls: ['./cities.component.scss']
})
export class CitiesComponent implements OnInit {

  // Set variables
  isLoading = false;
  cities: any;
  currentLocation: any;
  selectionType: string;
  show: boolean = true;

  constructor(@Inject(MAT_DIALOG_DATA) private data, public accessService: AccessService, private router: Router, private dialogRef: MatDialogRef<CitiesComponent>) { }

  ngOnInit(): void {
    // Check for cities
    if (this.data) {
      this.cities = this.data.cities;
      this.selectionType = this.data.selectionType;
    }

    // Get current location
    this.currentLocation = this.accessService.getLocation();
    if (!this.currentLocation) {
      this.show = false;
    } else {
      if (this.selectionType === 'default') {
        this.onClose(true);
      }
    }
  }

  // Select city
  selectCity(city: any) {
    const location = {
      id: city.id,
      name: city.name,
      description: city.description,
      tagline: city.tagline,
      bookingHours: city.bookingHours,
      openTime: city.openTime,
      closeTime: city.closeTime,
      image: city.image,
      isActive: city.isActive
    }
    localStorage.setItem("selfspin_customer_location", JSON.stringify(location));
    this.onClose(true);
  }

  // Close modal
  onClose(status: boolean) {
    this.dialogRef.close(status);
  }
}