import { ApplicationRef, Component, Inject, PLATFORM_ID } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { SwUpdate } from '@angular/service-worker';
import { interval } from 'rxjs';
import { AppUpdatesComponent } from './app-updates/app-updates.component';
import { AccessService } from './public/access/access.service';
import { SnackbarService } from './shared/notifications/snackbar.service';
import { isPlatformBrowser } from '@angular/common';
import { SearchService } from './public/search/search.service';

// Declare GTM dataLayer array
declare global {
  interface Window { dataLayer: any[]; }
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  constructor(@Inject(PLATFORM_ID) private platformId: any, private accessService: AccessService, public searchService: SearchService, public snackbarService: SnackbarService, private router: Router, public dialog: MatDialog, private activatedRoute: ActivatedRoute, private titleService: Title, private metaService: Meta, private readonly swUpdateService: SwUpdate, private appRef: ApplicationRef) {
    this.checkAppMaintenance();
    this.checkAppVersion(this.accessService.getVersion());
    this.checkCustomerSession(this.accessService.getSession());
    this.checkForUpdates();
    this.checkAppUpdates();
  }

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      // Get partner details
      this.accessService.getPartner('000000');

      // Get cities list
      this.searchService.getCities(0, 0);

      // Check user authentication on every route
      this.accessService.autoAuthUser();

      // Push GTM tags
      this.router.events.forEach(item => {
        if (item instanceof NavigationEnd) {
          // Set data layer for page route
          window.dataLayer = window.dataLayer || [];
          window.dataLayer.push({
            event: 'page',
            pageName: item.url
          });
        }
      });
    }
  }

  // Check customer session
  checkCustomerSession(sessionId: string) {
    if (isPlatformBrowser(this.platformId) && sessionId) {
      this.accessService.checkCustomerSession(sessionId).subscribe(resultData => {
        if (!resultData.isActive) {
          localStorage.removeItem("selfspin_customer_session_id");
        }
      }, (error: any) => {
        console.log(error);
      });
    }
  }

  // Check for maintenance
  checkAppMaintenance() {
    if (isPlatformBrowser(this.platformId)) {
      this.accessService.checkAppMaintenance().subscribe(resultData => {
        if (resultData.maintenance) {
          this.router.navigate(["/maintenance"]);
        }
      }, (error: any) => {
        console.log(error);
      });
    }
  }

  // Run for app been updated
  checkForUpdates() {
    if (isPlatformBrowser(this.platformId)) {
      if (!this.swUpdateService.isEnabled) {
        console.log("Service not enabled");
        return;
      }
      this.swUpdateService.available.subscribe(event => this.promptUpdate("APP"));
      this.swUpdateService.activated.subscribe(event => console.log("New app activated"));
    }
  }

  // Check app updates
  checkAppUpdates() {
    if (isPlatformBrowser(this.platformId)) {
      this.appRef.isStable.subscribe((isStable) => {
        if (isStable) {
          const timeInterval = interval(6 * 60 * 60 * 1000);
          timeInterval.subscribe(() => {
            this.swUpdateService.checkForUpdate().then(() => console.log('checking for updates'));
          })
        }
      });
    }
  }

  // Check app version
  checkAppVersion(version: string) {
    if (isPlatformBrowser(this.platformId)) {
      this.accessService.getAppVersion(version).subscribe(resultData => {
        if (!resultData.matched) {
          localStorage.setItem("selfspin_app_version", resultData.version);
          // this.promptUpdate("SERVER");
        }
      }, (error: any) => {
        console.log(error);
      });
    }
  }

  // Show update popup
  private promptUpdate(type: string): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = "300px";
    dialogConfig.position = { top: '5%' };
    dialogConfig.data = { type: type };
    this.dialog.open(AppUpdatesComponent, dialogConfig)
      .afterClosed().subscribe(status => {
        if (status) { }
      });
  }

}
