import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { AccessService } from 'src/app/public/access/access.service';

@Injectable({
  providedIn: 'root'
})
export class AccessGuard implements CanActivate {

  constructor(@Inject(PLATFORM_ID) private platformId: any, private accessService: AccessService, private router: Router) { }

  // Validate auth routes
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> | Promise<boolean> {
    const url: string = state.url;
    return this.checkAuthorization(url);
  }

  private checkAuthorization(url: string): Observable<boolean> | Promise<boolean> | boolean {
    if (isPlatformBrowser(this.platformId)) {
      return this.checkAuthOnBrowser(url);
    } else if (isPlatformServer(this.platformId)) {
      return this.checkAuthOnServer(url);
    } else {
      // Handle other platforms if needed
      console.error('Unknown platform');
      return false;
    }
  }

  private checkAuthOnBrowser(url: string): Observable<boolean> | Promise<boolean> | boolean {
    const isToken = this.accessService.getIsAuthFromLocalStorage();
    return this.handleAuthResult(isToken, url);
  }

  private checkAuthOnServer(url: string): Observable<boolean> | Promise<boolean> | boolean {
    const isToken = this.accessService.getIsAuthFromCookies();
    return this.handleAuthResult(isToken, url);
  }

  private handleAuthResult(isToken: boolean, url: string): Observable<boolean> | Promise<boolean> | boolean {
    if (!isToken) {
      // Store the attempted URL for redirecting
      this.accessService.redirectUrl = url;

      // Open sign in popup
      this.accessService.onSignin();

      // Navigate to the default url
      this.router.navigate(['/']);
      return false;
    }
    return true;
  }
}
