import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { MaterialModule } from './material/material.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NotFoundComponent } from './not-found/not-found.component';
import { HeaderComponent } from './layout/header/header.component';
import { AccessGuard } from './shared/guard/access.guard';
import { AccessInterceptor } from './shared/interceptor/access-interceptor';
import { ErrorsInterceptor } from './shared/interceptor/errors-interceptor';
import { CamelizePipe } from 'ngx-pipes';
import { AppUpdatesComponent } from './app-updates/app-updates.component';
import { MaintenanceComponent } from './maintenance/maintenance.component';
import { VerificationComponent } from './verification/verification.component';
import { CookieModule } from 'ngx-cookie';

@NgModule({
  declarations: [
    AppComponent,
    NotFoundComponent,
    HeaderComponent,
    AppUpdatesComponent,
    MaintenanceComponent,
    VerificationComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    MaterialModule,
    FlexLayoutModule,
    ReactiveFormsModule,
    FormsModule,
    CookieModule.forRoot(),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production
    })
  ],
  providers: [AccessGuard,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AccessInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorsInterceptor,
      multi: true
    }, CamelizePipe],
  bootstrap: [AppComponent]
})
export class AppModule { }
