import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { HttpInterceptor, HttpRequest, HttpHandler } from '@angular/common/http';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { AccessService } from 'src/app/public/access/access.service';

@Injectable()
export class AccessInterceptor implements HttpInterceptor {

    constructor(@Inject(PLATFORM_ID) private platformId: any, public accessService: AccessService) { }

    // Set token to header for every http request
    intercept(req: HttpRequest<any>, next: HttpHandler) {
        let authToken: string;
        if (isPlatformBrowser(this.platformId)) {
            authToken = this.accessService.getToken();
        } else if (isPlatformServer(this.platformId)) {
            authToken = this.accessService.getTokenFromCookies();
        }

        // Check for auth token of customer
        if (authToken) {
            req = req.clone({ headers: req.headers.set('Authorization', `Bearer ${authToken}`).set('Username', '') });
        } else {
            // Set secret auth token 
            const secretKey = req.headers.get("Authorization");
            const token = secretKey.split(" ")[1];
            req = req.clone({ headers: req.headers.set('Authorization', `Bearer ${token}`) });
        }
        return next.handle(req);
    }
}