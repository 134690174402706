import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';
import { Component, HostListener, Inject, OnDestroy, OnInit, PLATFORM_ID, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatSidenav } from '@angular/material/sidenav';
import { Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { ChangePasswordFormComponent } from 'src/app/private/account/profile/change-password-form/change-password-form.component';
import { AccessService } from 'src/app/public/access/access.service';
import { Cities } from 'src/app/public/search/search.model';
import { SearchService } from 'src/app/public/search/search.service';
import { SnackbarService } from 'src/app/shared/notifications/snackbar.service';
import { SignInComponent } from './sign-in/sign-in.component';
import { CitiesComponent } from './cities/cities.component';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {

  // Set variables 
  isLoading = false;
  userIsAuthenticated = false;
  private authListnerSub: Subscription;
  private userSubs: Subscription;
  userId: string;
  user: any;
  currentLocation: any;
  cities: Cities[] = [];
  private citiesSubs: Subscription;
  partner: any;
  private partnerSubs: Subscription;
  private timer: any;

  isHandset: Observable<BreakpointState> = this.breakpointObserver.observe(Breakpoints.Handset);
  @ViewChild('drawer') drawerRef: MatSidenav;

  isServer: boolean = isPlatformServer(this.platformId);

  constructor(@Inject(PLATFORM_ID) private platformId: any, private accessService: AccessService, public searchService: SearchService, private breakpointObserver: BreakpointObserver, private router: Router, public snackbarService: SnackbarService, public dialog: MatDialog) { }

  @HostListener('window:scroll', ['$event'])
  onScroll(event) {
    let headerElement = document.getElementById('header');
    if (headerElement) {
      if (window.pageYOffset > 0) {
        headerElement.classList.add('white-toolbar');
      } else {
        headerElement.classList.remove('white-toolbar');
      }
    }
  }

  ngOnInit(): void {
    // Check for valid user 
    this.userIsAuthenticated = this.accessService.getIsAuthFromLocalStorage();
    this.authListnerSub = this.accessService.getAuthStatusListner().
      subscribe(isAuthenticated => {
        this.userIsAuthenticated = isAuthenticated;
        // Check session
        if (this.userIsAuthenticated) {
          this.checkSession();
        }
      });

    // Check session
    if (this.userIsAuthenticated) {
      this.checkSession();
    }

    // Get user details
    this.getUserDetails();
    this.userSubs = this.accessService.getUserUpdatedListener().subscribe((userData: { user: any }) => {
      this.user = userData.user;
    });

    if (isPlatformBrowser(this.platformId)) {
      // Get cities 
      this.citiesSubs = this.searchService.getCitiesUpdatedListener().subscribe((citiesData: { cities: Cities[], citiesCount: number }) => {
        this.cities = citiesData.cities;
        if (this.cities.length > 0) {
          // Get current location
          this.currentLocation = this.accessService.getLocation();
          if (!this.currentLocation) {
            this.onCitySelect(this.cities, 'default');
          } else {
            // Get current city data
            const city = this.cities.find(item => item.id === this.currentLocation.id);
            if (city) {
              localStorage.setItem("selfspin_customer_location", JSON.stringify(city));
            }
          }
        }
      });

      // Get partner details
      this.partnerSubs = this.accessService.getPartnerUpdatedListener().subscribe((partnerData: { partner: any }) => {
        this.partner = partnerData.partner;
      });
      this.isLoading = false;
    }
  }

  // Get user details
  getUserDetails() {
    this.userId = this.accessService.getUserId();
    if (this.userId) {
      this.accessService.getUser(this.userId);
    }
  }

  // On sign in
  onSignin() {
    this.drawerRef.close();
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = "420px";
    dialogConfig.maxHeight = "90vh";
    dialogConfig.position = { top: '5%' };
    this.dialog.open(SignInComponent, dialogConfig)
      .afterClosed().subscribe(status => {
        if (status) {
          this.getUserDetails();
        }
      });
  }

  // On city
  onCitySelect(cities: any, selectionType: string) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = "640px";
    dialogConfig.data = {
      cities: cities,
      selectionType: selectionType
    };
    this.dialog.open(CitiesComponent, dialogConfig)
      .afterClosed().subscribe(status => {
        if (status) {
          this.currentLocation = this.accessService.getLocation();
          this.router.navigateByUrl('.', { skipLocationChange: true }).then(() => {
            this.router.navigate(['/' + this.currentLocation.name.toLowerCase() + '/rentals']);
          });
        }
      });
  }

  // On change password
  onChangePassowrd() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = "420px";
    dialogConfig.maxHeight = "90vh";
    dialogConfig.position = { top: '5%' };
    dialogConfig.data = {
      data: this.user
    };
    this.dialog.open(ChangePasswordFormComponent, dialogConfig)
      .afterClosed().subscribe(status => {
        if (status) {
        }
      });
  }

  // Check session data
  checkSession() {
    if (isPlatformBrowser(this.platformId)) {
      // Check local data
      this.timer = setInterval(() => {
        const authInformation = this.accessService.localDataExists();
        if (!authInformation) {
          clearInterval(this.timer);
          this.accessService.logOut();
          this.snackbarService.success('Session expired!', 'Dismiss', 'bottom', 1000);
        }
      }, 3000);
    }
  }

  // Check for home active
  isLinkActive(url): boolean {
    const queryParamsIndex = this.router.url.indexOf('?');
    const baseUrl = queryParamsIndex === -1 ? this.router.url : this.router.url.slice(0, queryParamsIndex);
    return baseUrl === url;
  }

  // Logout from app
  onLogout() {
    if (isPlatformBrowser(this.platformId)) {
      clearInterval(this.timer);
      this.accessService.logOut();
      this.snackbarService.success('Successfully logout.', 'Dismiss', 'bottom', 1000);
    }
  }

  ngOnDestroy() {
    if (this.authListnerSub) {
      this.authListnerSub.unsubscribe();
    }
    if (this.userSubs) {
      this.userSubs.unsubscribe();
    }
    if (this.timer) {
      clearInterval(this.timer);
    }
  }
}
