import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { Signin } from 'src/app/public/access/access.model';
import { AccessService } from 'src/app/public/access/access.service';
import { SnackbarService } from 'src/app/shared/notifications/snackbar.service';
import { ForgotPasswordComponent } from '../forgot-password/forgot-password.component';
import { MobileVerificationComponent } from '../mobile-verification/mobile-verification.component';
import { SignUpComponent } from '../sign-up/sign-up.component';

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss']
})
export class SignInComponent implements OnInit, OnDestroy {

  // Set variables
  isLoading = false;
  hide = true;
  countryCode: string;
  userIsAuthenticated = false;
  private authListnerSub: Subscription;

  constructor(@Inject(MAT_DIALOG_DATA) private data, private formBuilder: FormBuilder, public accessService: AccessService, public snackbarService: SnackbarService, private dialogRef: MatDialogRef<SignInComponent>, public dialog: MatDialog) { }

  // Form details
  signinDetails: Signin = {
    countryCode: '',
    mobile: '',
    password: ''
  }

  // Form builder
  signinForm = this.formBuilder.group({
    mobile: [null, [Validators.required, Validators.minLength(10), Validators.maxLength(10)]],
    password: [null, Validators.required],
  });

  ngOnInit(): void {
    // Set country code
    this.countryCode = "91";

    // Check for valid user 
    this.userIsAuthenticated = this.accessService.getIsAuthFromLocalStorage();
    this.authListnerSub = this.accessService.getAuthStatusListner().subscribe(authStatus => {
      this.isLoading = false;
      this.userIsAuthenticated = authStatus;
    });
  }

  // Form getter
  get signinControls() {
    return this.signinForm.controls;
  }

  // On form submit
  onSubmit() {
    // Check form validation
    if (this.signinControls.mobile.value) {
      this.isLoading = true;
      this.signinDetails = {
        countryCode: this.countryCode,
        mobile: this.signinControls.mobile.value
      }
      this.accessService.sendAccessOTP(this.signinDetails).subscribe(resultData => {
        this.isLoading = false;
        let codeDetails = {
          type: 'login',
          countryCode: resultData.countryCode,
          mobile: resultData.mobile,
          hash: resultData.hash
        }
        this.onMobileVerification(codeDetails);
        this.snackbarService.success('Verification code sent on your mobile number', 'Dismiss', 'bottom', 4000);
      }, (error: any) => {
        this.isLoading = false;
        if (error) {
          this.onSignup(this.signinControls.mobile.value);
        }
        console.log(error);
      });
    }
  }

  // On mobile verification
  onMobileVerification(codeDetails: any) {
    this.onClose(false);
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = "420px";
    dialogConfig.maxHeight = "90vh";
    dialogConfig.position = { top: '5%' };
    dialogConfig.data = {
      code: codeDetails
    };
    this.dialog.open(MobileVerificationComponent, dialogConfig)
      .afterClosed().subscribe(status => {
        if (status) {
        }
      });
  }

  // On signup
  onSignup(mobile: string) {
    this.onClose(false);
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = "460px";
    dialogConfig.maxHeight = "90vh";
    dialogConfig.position = { top: '5%' };
    dialogConfig.data = {
      mobile: mobile
    };
    this.dialog.open(SignUpComponent, dialogConfig)
      .afterClosed().subscribe(status => {
        if (status) {
        }
      });
  }

  // On forgot passowrd
  onForgotPassword() {
    this.onClose(true);
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = "420px";
    dialogConfig.maxHeight = "90vh";
    dialogConfig.position = { top: '5%' };
    this.dialog.open(ForgotPasswordComponent, dialogConfig)
      .afterClosed().subscribe(status => {
        if (status) {
        }
      });
  }

  // Number validation 
  onKeypress(event: any) {
    const pattern = /[0-9\ ]/;
    let inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode != 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  // Close modal
  onClose(status: boolean) {
    this.dialogRef.close(status);
  }

  ngOnDestroy() {
    if (this.authListnerSub) {
      this.authListnerSub.unsubscribe();
    }
  }
}