import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HeaderComponent } from './layout/header/header.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { MaintenanceComponent } from './maintenance/maintenance.component';
import { VerificationComponent } from './verification/verification.component';

const routes: Routes = [
  {
    path: 'maintenance',
    component: MaintenanceComponent
  },
  {
    path: 'verification/:token',
    component: VerificationComponent
  },
  {
    path: '',
    component: HeaderComponent,
    loadChildren: () => import("./layout/layout.module").then(m => m.LayoutModule)
  },
  {
    path: '**',
    component: NotFoundComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'enabled',
    anchorScrolling: 'enabled',
    scrollOffset: [0, 64],
    initialNavigation: 'enabled'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
