import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { Router } from '@angular/router';
import { AccessService } from '../public/access/access.service';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-maintenance',
  templateUrl: './maintenance.component.html',
  styleUrls: ['./maintenance.component.scss']
})
export class MaintenanceComponent implements OnInit {

  // Set variables
  version: number;

  constructor(@Inject(PLATFORM_ID) private platformId: any, private accessService: AccessService, private router: Router) { }

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      // Check app maintenance
      this.checkAppMaintenance();
    }
  }

  // Check app maintenance
  checkAppMaintenance() {
    this.accessService.checkAppMaintenance().subscribe(resultData => {
      if (resultData.maintenance) {
        this.router.navigate(["/maintenance"]);
      } else {
        this.router.navigate(['/'])
          .then(() => {
            window.location.reload();
          });
      }
    }, (error: any) => {
      console.log(error);
    });
  }

}
